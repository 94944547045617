import React from "react";
import closeIcon from "../../assets/images/quix-close.png";
import { WEB_URL } from "../../utils/config";
import { connect } from "react-redux";
import { failureMessagePopup } from "../../helper/helper";

const ShareLink = ({
  showModal,
  closeModal,
  itemID,
  cardType
}) => {

  if (!itemID) {
    failureMessagePopup('Error Message', 'Items doest not found!');
    return;
  }

  const itemId = Number(itemID) ? Number(itemID) : null;
  const uploadType = cardType ? cardType : '';

  return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner pb-0" style={{ height: '410px' }}>
          <iframe src={`${WEB_URL}/share/${itemId}/${uploadType}`} allow="clipboard-read; clipboard-write" style={{ height: '100%', width: '100%' }}></iframe>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  googleToken: state?.auth?.google_token,
});

export default connect(mapStateToProps, {})(
  ShareLink
);