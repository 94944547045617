import React, { useState } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import loaderIcon from "../../assets/images/light-loader.gif";
import feedBackBanner from "../../assets/images/quix-share-feedback-form.png";
import { useDispatch } from "react-redux";
import { failureMessagePopup, successMessagePopup } from "../../helper/helper";
import { userSendFeedBack } from "../../redux/actions/authentication";

const UserFeedBackModal = ({ showModal, closeModal }) => {
  const initialValues = {
    feedback: "",
    email: "",
  };

  const [loading, setLoading] = useState(false);
  const [senderDetails, setSenderDetail] = useState(initialValues);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSenderDetail({
      ...senderDetails,
      [name]: value,
    });
  };

  const handleSendFeedBack = () => {
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (senderDetails?.email === "") {
      alert("Please enter your email.");
      return false;
    } else if (!regex.test(senderDetails?.email)) {
      alert("Invalid email address!");
      return false;
    }

    if (senderDetails?.feedback === "") {
      alert("Please enter feedback.");
      return false;
    }
    if (senderDetails?.email && senderDetails?.feedback) {
      setLoading(true);
      const payload = {
        email: senderDetails?.email,
        feedback: senderDetails?.feedback,
      };
      dispatch(
        userSendFeedBack(payload, (type, res) => {
          if (type === "success") {
            successMessagePopup(
              "Success Message!",
              "Shared your feedback successfully."
            );
            setLoading(false);
            closeModal(false);
          } else {
            failureMessagePopup("Error Message!", res?.message);
            setLoading(false);
            closeModal(false);
          }
        })
      );
    }
  };

  return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Share your feedback with us</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <div className="quix-dashboard-popup-body-inner">
              <div className="feedback-img">
                <img src={feedBackBanner} width={'100%'} height={'100%'} />
              </div>
              <input
                type="text"
                name="email"
                maxLength={255}
                className="mb-2"
                placeholder="Enter Your Email"
                onChange={(e) => handleChange(e)}
                value={senderDetails?.email}
              />
              <textarea
                className="form-control mx-auto"
                id="email-message"
                placeholder="Enter Your Feedback"
                maxLength="300"
                name="feedback"
                onChange={(e) => handleChange(e)}
                value={senderDetails?.feedback}
              ></textarea>
            </div>
          </div>
          <div className="quix-dashboard-popup-footer">
            <div className="quix-dashboard-popup-footer-inner">
              <img
                style={{ display: !loading ? "none" : "block" }}
                className="loader-icon"
                src={loaderIcon}
              />
              <button
                onClick={() => handleSendFeedBack()}
                className="save-popup"
                disabled={loading}
              >
                Send
              </button>
              <button
                className="cancel-popup"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserFeedBackModal;
