import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import fbShareIcon from "../../assets/images/facebook-icon.png";
import linkedInShareIcon from "../../assets/images/linkedin-icon.png";
import twitterShareIcon from "../../assets/images/twitter-x-icon.png";
import evernoteShareIcon from "../../assets/images/icon-evernote.png";
import notionShareIcon from "../../assets/images/icon-notion.png";
import telegramShareIcon from "../../assets/images/icon-telegram.png";
import slackShareIcon from "../../assets/images/icon-slack.png";
import whatsAppShareIcon from "../../assets/images/icons-whatsapp.png";
import dropboxShareIcon from "../../assets/images/icon-dropbox.png";
import mediaShareIcon from "../../assets/images/icons-media-share.png";
import emailShareIcon from "../../assets/images/email-share-icon.png";
import qrcodeShareIcon from "../../assets/images/scan-qr-code-icon.png";
import youTubeShareIcon from "../../assets/images/quix-youtube-icon.png";
import googleDriveShareIcon from "../../assets/images/quix-google-drive-icon.png";
import teamShareIcon from "../../assets/images/icon-team.png";
import sharePointShareIcon from "../../assets/images/icon-sharepoints.png";
import oneNoteShareIcon from "../../assets/images/icon-one-note.png";
import ShareByEmail from "../../components/Modals/ShareByEmail";
import {
    checkServerURL,
    failureMessagePopup,
    shareOnMedia,
    successMessagePopup,
} from "../../helper/helper";
import { API_URL, localStoragePrefix, WEB_URL } from "../../utils/config";
import { manageVideoDetails } from "../../redux/actions/manageVideos";
import { manageScreenshotDetails } from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { LoginSocialGoogle } from "reactjs-social-login";
import QrGenrateCard from "../../components/Modals/QrGenrateCard";
import { getGeneralSettingcredentials } from "../../redux/actions/configServices";
import TwitterLogin from "../../views/login/components/SocialLogin/TwitterLogin";
import { shareOnSocialMedia } from "../../redux/actions/social-share";
import LinkedInLogin from "../../views/login/components/SocialLogin/LinkedInLogin";
import NotionLogin from "../../views/login/components/SocialLogin/NotionLogin";
import DropBoxLogin from "../../views/login/components/SocialLogin/DropBoxLogin";
import ShareDetailsForm from "../../components/Modals/ShareDetailsForm";
import LoadingPage from "../../views/loading-page/LoadingPage";
import SlackLogin from "../../views/login/components/SocialLogin/SlackLogin";
import WhatsAppLogin from "../login/components/SocialLogin/WhatsAppLogin";
import TelegramLogin from "../login/components/SocialLogin/TelegramLogin";
import SlackChannelsList from "../../components/Modals/SlackChannelsList";
import { useParams } from "react-router-dom";
import EvernoteLogin from "../login/components/SocialLogin/EvernoteLogin";
import MicroSoftLogin from "../login/components/SocialLogin/MicroSoftLogin";
import OneNoteList from "../../components/Modals/OneNoteList";

const ShareMedia = () => {
    const { id, type } = useParams();
    const itemID = id ?? "";
    const itemType = type ?? "";
    const dispatch = useDispatch();
    const [shareWithQr, setShareWithQr] = useState(false);
    const [showShareByEmail, setShareByEmailModal] = useState(false);
    const [shareDetailsObject, setShareDeatailsObject] = useState(null);
    const [configObj, setConfigObj] = useState({});
    const [isCopyText, setCopyText] = useState(false);
    const [showLoader, hideLoader] = useState(false);
    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );

    useEffect(() => {
        if (!itemID) return;
        const payload = {
            id: itemID,
        };
        if (itemType === "video") {
            dispatch(
                manageVideoDetails(payload, (type, res) => {
                    if (type === "success") {
                        setShareDeatailsObject(res);
                    }
                })
            );
        } else if (itemType === "image") {
            dispatch(
                manageScreenshotDetails(payload, (type, res) => {
                    if (type === "success") {
                        setShareDeatailsObject(res);
                    }
                })
            );
        }
    }, [itemID]);

    let itemPath = "";
    itemPath =
        itemType === "image"
            ? checkServerURL(API_URL, shareDetailsObject?.path)
            : checkServerURL(API_URL, shareDetailsObject?.modified_path);

    useEffect(() => {
        getGeneralSettingcredentials((res) => {
            const googleDetails = res?.google && res?.google;
            const linkedinDetails = res?.linkedin && res?.linkedin;
            const facebook =
                res?.facebook?.facebook_client_id && res?.facebook?.facebook_client_id;
            const twitter =
                res?.twitter?.twitter_client_id && res?.twitter?.twitter_client_id;
            const dropbox = res?.dropbox && res?.dropbox;
            const notion = res?.notion && res?.notion;
            const slack = res?.notion && res?.slack;
            const microsoft = res?.microsoft && res?.microsoft;

            setConfigObj({
                google: googleDetails,
                facebook: facebook,
                linkedin: linkedinDetails,
                twitter: twitter,
                dropbox: dropbox,
                notion: notion,
                slack: slack,
                microsoft: microsoft,
            });
        });
    }, []);

    const shareViaSocialMedia = (type, URL) => {
        // Check if the sharing type is Facebook
        if (type === "facebook") {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    URL
                )}`,
                "facebookpopup",
                "popup"
            );
        }
    };

    const handleUpload = (e) => {
        jQuery(".google-uploader-outer .share-on-media").removeClass("active");
        jQuery(e.currentTarget).addClass("active");
    };

    const handleResponse = async (response) => {
        const uploadType = jQuery(
            ".google-uploader-outer .share-on-media.active"
        ).attr("action-type");
        let authToken = response?.profile?.access_token;
        let scope = "";
        if (uploadType === "google") {
            scope = "https://www.googleapis.com/auth/drive.file";
        } else if (uploadType === "youtube") {
            scope = "https://www.googleapis.com/auth/youtube.upload";
        }
        const payload = {
            uploadType: uploadType,
            mediaType: itemType,
            id: itemID,
            authObject: {
                accessToken: `access_token=${authToken}&token_type=Bearer&expires_in=3599&scope=${scope}`,
            },
        };
        hideLoader(true);
        // Dispatch the upload action to upload the video
        dispatch(
            shareOnSocialMedia(payload, (type, res) => {
                if (type === "success") {
                    hideLoader(false);
                    successMessagePopup("Success Message!", "Upload successfully!");
                } else if (type === "failed") {
                    hideLoader(false);
                    failureMessagePopup("Error Message", "Something went wrong!");
                }
            })
        );
    };

    // Twitter callback function
    const twitterCallBack = (type, res) => {
        if (type === "success") {
            const data = res?.data;
            const payload = {
                uploadType: "twitter",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: data?.access_token,
                    accessSecret: data?.access_token_secret,
                    userId: data?.id,
                },
            };
            if (payload?.id !== "") {
                hideLoader(true);
                dispatch(
                    shareOnSocialMedia(payload, (type, res) => {
                        if (type === "success") {
                            successMessagePopup("Success Message!", "Upload successfully!");
                            hideLoader(false);
                        } else if (type === "failed") {
                            failureMessagePopup("Error Message", res?.message);
                            hideLoader(false);
                        }
                    })
                );
            } else {
                console.log("Information not found!");
            }
        } else if (type === "failed") {
            console.log("Something went wrong!");
        }
    };

    // Linkedin callback function
    const linkedinCallBack = async (type, res) => {
        if (type === "success") {
            const payload = {
                uploadType: "linkdin",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: res?.token,
                },
            };

            if (payload?.id !== "") {
                hideLoader(true);
                dispatch(
                    shareOnSocialMedia(payload, (type, res) => {
                        if (type === "success") {
                            successMessagePopup("Success Message!", "Upload successfully!");
                            hideLoader(false);
                        } else if (type === "failed") {
                            failureMessagePopup("Error Message", res?.message);
                            hideLoader(false);
                        }
                    })
                );
            } else {
                console.log("Information not found!");
            }
        } else if (type === "failed") {
            console.log("Something went wrong!");
        }
    };

    const [notionDetailModal, setNotionDetailModal] = useState(false);
    const [notionDetailCallbackData, setNotionCallbackData] = useState(null);

    const notionDetailsCallback = (res) => {
        setNotionDetailModal(false);
        if (notionDetailCallbackData !== null) {
            hideLoader(true);
            const dataObj = notionDetailCallbackData;
            const formDatails = res;

            const payload = {
                uploadType: "notion",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: dataObj?.data?.token,
                    databaseId: dataObj?.data?.note?.databases?.results?.[0]?.id,
                    title: formDatails?.title,
                    description: formDatails?.description,
                },
            };

            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message!", "Upload successfully!");
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        }
    };
    // Notion callback function
    const notionCallBack = (type, res) => {
        if (type === "success") {
            setNotionDetailModal(true);
            setNotionCallbackData(res);
        } else {
            setNotionCallbackData(null);
        }
    };

    // DropBox callback function
    const dropBoxCallBack = (type, res) => {
        if (type === "success") {
            hideLoader(true);
            const payload = {
                uploadType: "dropbox",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: res?.data?.accessToken,
                },
            };
            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message!", "Upload successfully!");
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        } else {
            console.log(res, "Something went wrong!");
        }
    };

    const [slackListModal, setSlackListModal] = useState(false);
    const [slackListCallbackData, setSlackListCallbackData] = useState();

    const slackCallBack = (type, res) => {
        if (type === "success") {
            setSlackListModal(true);
            setSlackListCallbackData(res);
        } else {
            setSlackListModal(false);
            setNotionCallbackData(null);
        }
    };

    const handleSlackCallbackList = (res) => {
        hideLoader(true);
        setSlackListModal(false);
        const payload = {
            uploadType: "slack",
            mediaType: itemType,
            id: itemID,
            authObject: {
                accessToken: res?.token,
                channel: res?.channel,
            },
        };
        dispatch(
            shareOnSocialMedia(payload, (type, res) => {
                if (type === "success") {
                    successMessagePopup("Success Message!", "Upload successfully!");
                    hideLoader(false);
                } else if (type === "failed") {
                    failureMessagePopup("Error Message", "Something went wrong!");
                    hideLoader(false);
                }
            })
        );
    };

    const [evernoteDetailModal, setEvernoteDetailModal] = useState(false);
    const [evernoteDetailCallbackData, setEvernoteCallbackData] = useState(null);
    const evernoteDetailsCallback = (res) => {
        setEvernoteDetailModal(false);
        if (evernoteDetailCallbackData !== null) {
            hideLoader(true);
            const dataObj = evernoteDetailCallbackData;
            const formDatails = res;
            const payload = {
                uploadType: "evernote",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: dataObj?.accessToken,
                    title: formDatails?.title,
                    description: formDatails?.description,
                },
            };
            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup(
                            "Success Message!",
                            "Evernote Upload successfully!"
                        );
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        }
    };
    const evernoteCallBack = (type, res) => {
        if (type === "success") {
            setEvernoteDetailModal(true);
            setEvernoteCallbackData(res);
        } else {
            setEvernoteDetailModal(false);
            setEvernoteCallbackData(null);
        }
    };

    const [onenoteDetailModal, setOnenoteDetailModal] = useState(false);
    const [onenoteDetailCallbackData, setOnenoteCallbackData] = useState(null);
    const microsoftCallBack = (type, res) => {
        if (type === "success") {
            setOnenoteDetailModal(true);
            setOnenoteCallbackData(res);
        } else {
            setOnenoteDetailModal(false);
            setOnenoteCallbackData(null);
        }
    };

    const handleOnenoteCallbackList = (res) => {
        hideLoader(true);
        setOnenoteDetailModal(false);
        if (onenoteDetailCallbackData?.accessToken) {
            const payload = {
                uploadType: "onenote",
                mediaType: itemType,
                vid: itemID,
                authObject: {
                    accessToken: onenoteDetailCallbackData?.accessToken,
                    notebookId: res?.channel,
                },
            };
            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message!", "Upload successfully!");
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        }
    };

    const handleCopy = (itemPath) => {
        if (isCopyText) return;
        navigator.clipboard
            .writeText(itemPath)
            .then(() => {
                setCopyText(true);
                setTimeout(() => {
                    setCopyText(false);
                }, 2000);
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
                setCopyText(false);
            });
    };

    return (
        <div>
            <div className="social-share-page card border-0">
                <div className="social-share-main-heading text-center">
                    <h5>Share with Anyone</h5>
                </div>
                {showLoader && <LoadingPage opacity={"0.6"} />}
                <div className="card-body">
                    <div className="socials-media-options text-center  mt-3">
                        <div className="title-option">
                            <h6>Share on Social Media</h6>
                        </div>
                        <div className="socials-media-platforms">
                            <div className="quix-social-share-form">
                                <div
                                    className="share-on-media share-facebook"
                                    onClick={() => shareViaSocialMedia("facebook", itemPath)}
                                >
                                    <img src={fbShareIcon} title="Facebook" />
                                </div>
                            </div>
                            {configObj?.linkedin?.linkedin_client_id ? (
                                <LinkedInLogin
                                    cliendID={configObj?.linkedin?.linkedin_client_id}
                                    redirect_Url={`${WEB_URL}/dashboard/callback`}
                                    scope={"openid,profile,email,w_member_social"}
                                    callback={(type, res) => linkedinCallBack(type, res)}
                                >
                                    <div className="share-on-media share-linkedIn">
                                        <img src={linkedInShareIcon} title="Linkedin" />
                                    </div>
                                </LinkedInLogin>
                            ) : (
                                <div
                                    className="share-on-media share-linkedIn"
                                    style={{ opacity: "0.5" }}
                                >
                                    <img src={linkedInShareIcon} title="Coming Soon" />
                                </div>
                            )}
                            <TwitterLogin
                                redirect_Url={`${WEB_URL}/dashboard/callback`}
                                callback={(type, res) => twitterCallBack(type, res)}
                            >
                                <div className="share-on-media share-Twitter">
                                    <img src={twitterShareIcon} title="Twitter(X)" />
                                </div>
                            </TwitterLogin>
                            <WhatsAppLogin URL={itemPath}>
                                <div className="share-on-media share-Twitter">
                                    <img src={whatsAppShareIcon} title="WhatsApp" />
                                </div>
                            </WhatsAppLogin>
                            <TelegramLogin URL={itemPath}>
                                <div className="share-on-media share-Twitter">
                                    <img src={telegramShareIcon} title="Telegram" />
                                </div>
                            </TelegramLogin>
                            <EvernoteLogin
                                redirect_Url={`${WEB_URL}/dashboard/callback`}
                                callback={(type, res) => evernoteCallBack(type, res)}
                            >
                                <div className="share-on-media share-Twitter">
                                    <img src={evernoteShareIcon} title="Evernote" />
                                </div>
                            </EvernoteLogin>
                            {/* evernoteShareIcon */}
                            {configObj?.dropbox?.dropbox_api_key ? (
                                <DropBoxLogin
                                    redirect_Url={`${WEB_URL}/dashboard/callback`}
                                    apiKey={configObj?.dropbox?.dropbox_api_key}
                                    callback={(type, res) => dropBoxCallBack(type, res)}
                                >
                                    <div className="share-on-media share-DropBox">
                                        <img src={dropboxShareIcon} title="Dropbox" />
                                    </div>
                                </DropBoxLogin>
                            ) : (
                                <div
                                    className="share-on-media share-DropBox"
                                    style={{ opacity: "0.5" }}
                                >
                                    <img src={dropboxShareIcon} title="Coming Soon" />
                                </div>
                            )}
                            {configObj?.notion?.notion_client_id ? (
                                <NotionLogin
                                    cliendID={configObj?.notion?.notion_client_id}
                                    callback={(type, res) => notionCallBack(type, res)}
                                >
                                    <div className="share-on-media share-Notion">
                                        <img src={notionShareIcon} title="Notion" />
                                    </div>
                                </NotionLogin>
                            ) : (
                                <div
                                    className="share-on-media share-Notion"
                                    style={{ opacity: "0.5" }}
                                >
                                    <img src={notionShareIcon} title="Coming Soon" />
                                </div>
                            )}
                            {/* slack */}
                            {configObj?.slack?.slack_client_id ? (
                                <SlackLogin
                                    callback={(type, res) => slackCallBack(type, res)}
                                    clientId={configObj?.slack?.slack_client_id}
                                    redirect_Url={`${WEB_URL}/dashboard/callback`}
                                >
                                    <div className="share-on-media share-slack">
                                        <img src={slackShareIcon} title="Slack" />
                                    </div>
                                </SlackLogin>
                            ) : (
                                <div
                                    className="share-on-media share-slack"
                                    style={{ opacity: "0.5" }}
                                >
                                    <img src={slackShareIcon} title="Coming Soon" />
                                </div>
                            )}
                            {itemType === "image" && (
                                <>
                                    {configObj?.google?.google_client_id ? (
                                        <LoginSocialGoogle
                                            false
                                            client_id={configObj?.google?.google_client_id}
                                            onResolve={({ provider, data }) => {
                                                handleResponse({ provider, profile: data });
                                            }}
                                            cookiePolicy={"single_host_origin"}
                                            scope={
                                                "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/youtube.upload"
                                            }
                                            isOnlyGetToken={true}
                                            onReject={(err) => {
                                                console.log(err);
                                            }}
                                        >
                                            <div className="google-uploader-outer">
                                                <div
                                                    className="share-on-media google-uploader"
                                                    onClick={(e) => handleUpload(e)}
                                                    action-type={"google"}
                                                >
                                                    <img src={googleDriveShareIcon} title="Drive" />
                                                </div>
                                            </div>
                                        </LoginSocialGoogle>
                                    ) : (
                                        <div className="google-uploader-outer">
                                            <div
                                                className="share-on-media google-uploader"
                                                action-type={"google"}
                                                style={{ opacity: "0.5" }}
                                            >
                                                <img src={googleDriveShareIcon} title="Coming Soon" />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {itemType === "video" && (
                                <>
                                    {configObj?.google?.google_client_id ? (
                                        <LoginSocialGoogle
                                            false
                                            client_id={configObj?.google?.google_client_id}
                                            onResolve={({ provider, data }) => {
                                                handleResponse({ provider, profile: data });
                                            }}
                                            cookiePolicy={"single_host_origin"}
                                            scope={
                                                "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/youtube.upload"
                                            }
                                            isOnlyGetToken={true}
                                            onReject={(err) => {
                                                console.log(err);
                                            }}
                                        >
                                            <div className="google-uploader-outer">
                                                <div
                                                    className="share-on-media google-uploader"
                                                    onClick={(e) => handleUpload(e)}
                                                    action-type={"google"}
                                                >
                                                    <img src={googleDriveShareIcon} title="Drive" />
                                                </div>
                                                <div
                                                    className="share-on-media google-uploader"
                                                    onClick={(e) => handleUpload(e)}
                                                    action-type={"youtube"}
                                                >
                                                    <img src={youTubeShareIcon} title="Youtube" />
                                                </div>
                                            </div>
                                        </LoginSocialGoogle>
                                    ) : (
                                        <div className="google-uploader-outer">
                                            <div
                                                className="share-on-media google-uploader"
                                                action-type={"google"}
                                                style={{ opacity: "0.5" }}
                                            >
                                                <img src={googleDriveShareIcon} title="Coming Soon" />
                                            </div>
                                            <div
                                                className="share-on-media google-uploader"
                                                action-type={"youtube"}
                                                style={{ opacity: "0.5" }}
                                            >
                                                <img src={youTubeShareIcon} title="Coming Soon" />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            <div
                                className="share-on-media share-DropBox"
                                style={{ opacity: "0.5" }}
                            >
                                <img src={teamShareIcon} title="Coming Soon" />
                            </div>
                            <div
                                className="share-on-media share-DropBox"
                                style={{ opacity: "0.5" }}
                            >
                                <img src={sharePointShareIcon} title="Coming Soon" />
                            </div>
                            {configObj?.microsoft?.microsoftazure_client_id ? (
                                <MicroSoftLogin
                                    clientId={configObj?.microsoft?.microsoftazure_client_id}
                                    redirect_Url={`${WEB_URL}/dashboard/callback`}
                                    callback={(type, res) => microsoftCallBack(type, res)}
                                >
                                    <div className="share-on-media share-Onenote">
                                        <img src={oneNoteShareIcon} title="OneNote" />
                                    </div>
                                </MicroSoftLogin>
                            ) : (
                                <div
                                    className="share-on-media share-Onenote"
                                    style={{ opacity: "0.5" }}
                                >
                                    <img src={oneNoteShareIcon} title="Coming Soon" />
                                </div>
                            )}

                            {/* setShareWithQr */}
                            <div
                                className="share-on-media share-email"
                                onClick={() => setShareByEmailModal(!showShareByEmail)}
                            >
                                <img src={emailShareIcon} title="Email" />
                            </div>
                            <div
                                className="share-on-media share-qr-code"
                                onClick={() => {
                                    setShareWithQr(true);
                                }}
                            >
                                <img src={qrcodeShareIcon} title="QR Code" />
                            </div>
                            <div
                                className="share-on-media share-third-party"
                                onClick={() => shareOnMedia(itemPath)}
                            >
                                <img src={mediaShareIcon} title="3rd party Applications" />
                            </div>
                        </div>
                    </div>
                    <div className="social-share-with-link mt-4">
                        <div className="title-option text-center">
                            <h6>Copy and Share Link</h6>
                        </div>
                        <div className="quix-email-share-form d-flex gap-1">
                            <input
                                defaultValue={itemPath}
                                type="text"
                                name="share-Link"
                                id="share-screenshot-link"
                                className="form-control rounded-2"
                                maxLength={255}
                            />
                            <button
                                className="send-link-share"
                                onClick={() => handleCopy(itemPath)}
                            >
                                {isCopyText ? "Copied" : "Copy"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {slackListModal && (
                <SlackChannelsList
                    closeModal={setSlackListModal}
                    ChannelsList={slackListCallbackData}
                    callback={(e) => handleSlackCallbackList(e)}
                />
            )}
            {notionDetailModal && (
                <ShareDetailsForm
                    closeModal={setNotionDetailModal}
                    data={shareDetailsObject}
                    callback={(e) => notionDetailsCallback(e)}
                />
            )}
            {shareWithQr && (
                <QrGenrateCard
                    showModal={shareWithQr}
                    closeModal={setShareWithQr}
                    path={itemPath}
                />
            )}
            {showShareByEmail && (
                <ShareByEmail
                    showModal={showShareByEmail}
                    closeModal={setShareByEmailModal}
                    itemType={itemType}
                    itemID={itemID}
                    userID={userID}
                />
            )}
            {evernoteDetailModal && (
                <ShareDetailsForm
                    closeModal={setEvernoteDetailModal}
                    data={shareDetailsObject}
                    callback={(e) => evernoteDetailsCallback(e)}
                />
            )}
            {onenoteDetailModal && (
                <OneNoteList
                    ChannelsList={onenoteDetailCallbackData}
                    callback={(e) => handleOnenoteCallbackList(e)}
                    closeModal={setOnenoteDetailModal}
                />
            )}
        </div>
    );
};

ShareMedia.dafaultProps = {
    closeModal: function () { },
};

export default ShareMedia;
