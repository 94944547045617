import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import { Link, useLocation, useParams } from "react-router-dom";
import leftArrowIcon from "../../assets/images/arrow-left.png";
import userDummyIcon from "../../assets/images/dumy-user.jpg";
import smsIcon from "../../assets/images/sms-icon.png";
import videoIcon from "../../assets/images/video-icon.png";
import screenshotIcon from "../../assets/images/gallery-icon.png";
import trashIcon from "../../assets/images/trash-icon.png";
import trashUserIcon from "../../assets/images/tash-user-icon.png";
import actionIcon from "../../assets/images/action-icon.png";
import editVideoIcon from "../../assets/images/video-icon.png";
import editImageIcon from "../../assets/images/image-icon.png";
import MediaContentCard from "../../components/Cards/MediaContentCard";
import Table from "../../components/Table/Table";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import { connect, useDispatch } from "react-redux";
import {
    manageScreenShots,
    manageSearchScreenShots,
} from "../../../redux/actions/manageScreenshots";
import { checkServerURL, sg_format_bytes } from "../../../helper/helper";
import dateFormat from "dateformat";
import {
    manageSearchVideos,
    manageVideos,
} from "../../../redux/actions/manageVideos";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import EditActionModal from "../../components/Modal/EditActionModal";
import VideoViewCard from "../../components/Cards/VideoViewCard";
import ScreenshotViewCard from "../../components/Cards/ScreenshotViewCard";
import EditUserProfile from "./components/EditUserProfile";
import { getUserByIdAdmin } from "../../../redux/actions/admin/users";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { deleteVideoAdmin } from "../../../redux/actions/admin/videos";
import { successMessageModal } from "../../components/Modal/MessagesModal";
import { deleteScreenshotAdmin } from "../../../redux/actions/admin/screenshots";
import dummyUserIcon from '../../assets/images/dumy-user.jpg';

const ManageUserProfile = ({
    userDetails,
    screenshotList,
    screenshotLoading,
    screenshotNotFound,
    videoList,
    videoLoading,
    videoNotFound,
}) => {
    const param = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showScreenshotConfirmModal, setShowScreenshotConfirmModal] =
        useState(false);
    const [showVideoConfirmModal, setShowVideoConfirmModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showScreenshotModal, setShowScreenshotModal] = useState(false);
    const [videoCardData, setVideCardData] = useState(null);
    const [screenshotCardData, setScreenshotCardData] = useState(null);
    const [loading, setLoading] = useState(false);
    const adminID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const userData = userDetails?.user_details ? userDetails?.user_details : [];
    const userLocationData = location?.state?.data;
    const userID =
        userLocationData?.id !== "undefined" ? userLocationData?.id : param?.id;
    const userToken =
        userLocationData?.access_token !== "undefined"
            ? userLocationData?.access_token
            : userData?.access_token;
    const screenshotData = screenshotList?.data ? screenshotList?.data : [];
    const videoData = videoList?.data ? videoList?.data : [];
    const userPayload = {
        adminID: adminID,
        id: userID,
    };
    useEffect(() => {
        dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
    }, [userID]);
    const userProfile =
        !userData?.picture ||
            userData?.picture === "null" ||
            userData?.picture === null
            ? dummyUserIcon
            : checkServerURL(API_URL, userData?.picture);
    const userName = userData?.name && userData?.name;
    const userEmail = userData?.email && userData?.email;

    useEffect(() => {
        if (userID) {
            // get all users videos
            dispatch(manageVideos(Number(userID), userToken));
            // get all users screenshots
            dispatch(manageScreenShots(Number(userID), userToken));
        }
    }, [userID]);

    // video table columns
    const videosColumn = [
        {
            Header: (
                <input className="form-check-input check-items" type="checkbox" />
            ),
            accessor: "checkitem",
            disableSortBy: true,
            Cell: (row) => (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    value=""
                />
            ),
        },
        { Header: "#", accessor: "id" },
        {
            Header: "Content",
            accessor: "video",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail ||
                                row?.row?.original?.thumbnail === "null" ||
                                row?.row?.original?.thumbnail === null
                                ? ""
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${row?.row?.original?.file_duration} min`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>{dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")}</>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowVideoModal(!showVideoModal);
                                    setVideCardData(row?.row?.original);
                                }}
                            >
                                <img src={editVideoIcon} />
                                <span>Video details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setShowVideoConfirmModal(!showVideoConfirmModal);
                                    setVideCardData(row?.row?.original);
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    // screen table column
    const screenshotsColumn = [
        {
            Header: (
                <input className="form-check-input check-items" type="checkbox" />
            ),
            accessor: "checkitem",
            disableSortBy: true,
            Cell: (row) => (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    value=""
                />
            ),
        },
        { Header: "#", accessor: "id" },
        {
            Header: "Content",
            accessor: "screenshot",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail ||
                                row?.row?.original?.thumbnail === "null" ||
                                row?.row?.original?.thumbnail === null
                                ? ""
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${sg_format_bytes(
                            row?.row?.original?.file_size
                        )} - Screenshot`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>{dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")}</>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowScreenshotModal(!showScreenshotModal);
                                    setScreenshotCardData(row?.row?.original);
                                }}
                            >
                                <img src={editImageIcon} />
                                <span>Screenshot details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setShowScreenshotConfirmModal(!showScreenshotConfirmModal);
                                    setScreenshotCardData(row?.row?.original);
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    const userCsvData = {
        filename: "user-data",
        data: [
            { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
            { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
            { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
        ],
    };

    // handle delete video
    const handleDeleteVideo = (res) => {
        if (res === true) {
            const payload = {
                admin_id: Number(adminID),
                id: Number(videoCardData?.id),
            };
            dispatch(
                deleteVideoAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted video.",
                            "video will permanently delete successfully."
                        );
                        setShowVideoConfirmModal(false);
                        setLoading(false);
                        dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
                        dispatch(manageVideos(Number(userID), userToken));
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    // handle delete screenshots
    const handleDeleteScreenShot = (res) => {
        if (res === true) {
            const payload = {
                admin_id: Number(adminID),
                id: Number(screenshotCardData?.id),
            };
            dispatch(
                deleteScreenshotAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted screenshot.",
                            "Screenshot will permanently delete successfully."
                        );
                        setShowScreenshotConfirmModal(false);
                        setLoading(false);
                        dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
                        dispatch(manageScreenShots(Number(userID), userToken));
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    // handle search screenshot
    const handleSearchScreenshot = (text) => {
        const payload = {
            user_id: Number(userID),
            access_token: userToken,
            search_text: text,
        };
        dispatch(manageSearchScreenShots(payload));
    };

    // handle search video
    const handleSearchVideo = (text) => {
        const payload = {
            user_id: Number(userID),
            access_token: userToken,
            search_text: text,
        };
        dispatch(manageSearchVideos(payload));
    };

    // reset searched video and screenshot
    const resetScreenshotAndVideo = () => {
        dispatch(manageScreenShots(Number(userID), userToken));
        dispatch(manageVideos(Number(userID), userToken));
    };

    return (
        <div className="manage-user-screenshot-and-video">
            <AdminLayout>
                <div className="manage-user-screenshot-and-video-content">
                    <div className="user-management-section">
                        <div className="user-management-header">
                            <Link
                                to={"/admin/manage-users"}
                                className="user-manage-bk-btn theme-btn-outline btn"
                            >
                                <img src={leftArrowIcon} />
                                Back to User management
                            </Link>
                        </div>
                        <div className="user-profile-details-section d-flex justify-content-between">
                            <div className="user-profile-details d-flex gap-3">
                                <div className="card profile-card">
                                    <img src={userProfile} alt="profile" />
                                </div>
                                <div className="profile-detail">
                                    <div className="user-name-and-status d-flex gap-3 mt-2">
                                        <h4 className="text-clip">{userName}</h4>
                                        <span>Active pro plan</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={smsIcon} />
                                        <a href={`mailto:${userEmail}`}>{userEmail}</a>
                                    </div>
                                    <div className="statistics-content mt-4 mb-0">
                                        <div className="statistics-card d-flex align-items-center">
                                            <h6 className="d-flex align-items-center">
                                                <img src={videoIcon} />
                                                {userDetails?.total_video !== "undefined"
                                                    ? userDetails?.total_video
                                                    : 0}
                                            </h6>
                                            <span>Video Recording</span>
                                        </div>
                                        <div className="statistics-card d-flex align-items-center">
                                            <h6 className="d-flex align-items-center">
                                                <img src={screenshotIcon} />
                                                {userDetails?.total_screenshots !== "undefined"
                                                    ? userDetails?.total_screenshots
                                                    : 0}
                                            </h6>
                                            <span>Screenshots & Images</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-details-action  d-flex gap-2">
                                <button
                                    className="btn theme-blue-btn edit-profile-btn"
                                    onClick={() => setShowEditUserModal(!showEditUserModal)}
                                >
                                    Edit Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="data-content-section">
                    <Filters
                        filterList={[
                            {
                                id: "video",
                                className: "video active",
                                title: "Video",
                            },
                            {
                                id: "screenshot",
                                className: "screenshot",
                                title: "Screenshot",
                            },
                        ]}
                    />
                    <FilterContent
                        filterContent={[
                            {
                                id: "video",
                                className: "show active video-tab",
                                childrenData: (
                                    <Table
                                        tableData={videoData}
                                        tableColumns={videosColumn}
                                        csvData={userCsvData}
                                        isLoading={videoLoading}
                                        notFound={videoNotFound}
                                        searchCallBack={(e) => handleSearchVideo(e)}
                                        resetCallBack={() => resetScreenshotAndVideo()}
                                        searchBar={true}
                                        tableType={'user_video'}
                                    />
                                ),
                            },
                            {
                                id: "screenshot",
                                className: "screenshot-tab",
                                childrenData: (
                                    <Table
                                        tableData={screenshotData}
                                        tableColumns={screenshotsColumn}
                                        csvData={userCsvData}
                                        isLoading={screenshotLoading}
                                        notFound={screenshotNotFound}
                                        searchCallBack={(e) => handleSearchScreenshot(e)}
                                        resetCallBack={() => resetScreenshotAndVideo()}
                                        searchBar={true}
                                        tableType={'user_screenshot'}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                {showEditUserModal && (
                    <EditUserProfile
                        userData={userData}
                        isShowModal={showEditUserModal}
                        closeModal={setShowEditUserModal}
                    />
                )}
                {showVideoModal && (
                    <VideoViewCard
                        closeModal={setShowVideoModal}
                        cardData={videoCardData}
                    />
                )}
                {showVideoConfirmModal && (
                    <ConfirmationModal
                        isShowModal={showVideoConfirmModal}
                        closeModal={setShowVideoConfirmModal}
                        callBack={handleDeleteVideo}
                        isLoading={false}
                        isDisabled={false}
                        icon={trashUserIcon}
                        btnText={"Permanently delete"}
                        messageTitle={
                            <>
                                Would you like to permanently
                                <br />
                                delete this video?
                            </>
                        }
                        description={
                            <>
                                Once deleted, this video will
                                <br />
                                no longer be accessible.
                            </>
                        }
                    />
                )}
                {showScreenshotModal && (
                    <ScreenshotViewCard
                        closeModal={setShowScreenshotModal}
                        cardData={screenshotCardData}
                    />
                )}
                {showScreenshotConfirmModal && (
                    <ConfirmationModal
                        isShowModal={showScreenshotConfirmModal}
                        closeModal={setShowScreenshotConfirmModal}
                        callBack={handleDeleteScreenShot}
                        isLoading={false}
                        isDisabled={false}
                        icon={trashUserIcon}
                        btnText={"Permanently delete"}
                        messageTitle={
                            <>
                                Would you like to permanently
                                <br />
                                delete this screenshot?
                            </>
                        }
                        description={
                            <>
                                Once deleted, this screenshot will
                                <br />
                                no longer be accessible.
                            </>
                        }
                    />
                )}
            </AdminLayout>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state?.users?.user_details,
    screenshotList: state?.screen_shorts?.list,
    screenshotLoading: state?.screen_shorts?.is_loading,
    screenshotNotFound: state?.screen_shorts?.not_found,
    videoList: state?.videos?.list,
    videoLoading: state?.videos?.is_loading,
    videoNotFound: state?.videos?.not_found,
});

export default connect(mapStateToProps, {
    getUserByIdAdmin,
    manageScreenShots,
    manageVideos,
})(ManageUserProfile);
