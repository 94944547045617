import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import uploaderIcon from "../../assets/images/quix-save-cloud.png";
import {
  manageScreenShots,
  uploadScreenShot,
} from "../../redux/actions/manageScreenshots";
import { connect, useDispatch } from "react-redux";
import {
  failureMessagePopup,
  getVideoDuration,
  sq_profile_progress,
  successMessagePopup,
} from "../../helper/helper";
import {
  manageVideos,
  uploadRecordingVideo,
} from "../../redux/actions/manageVideos";
import { manageUserProgress } from "../../redux/actions/authentication";
import Multiselect from "multiselect-react-dropdown";
import { addTags, getAllTags } from "../../redux/actions/manageTags";
import { localStoragePrefix } from "../../utils/config";

const FileUploader = ({ showModal, closeModal, userID, progressDetails, allTags, userDetails }) => {
  const fileFormat = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "video/mp4",
    "video/webm",
    "video/avi",
    "video/mov",
  ];
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  const [formDetails, setFormDetails] = useState({
    title: "",
    summary: "",
    fileData: null,
  });

  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [tags, setTags] = useState('');
  const [selectTags, setSelectTags] = useState([]);
  // ***************** All tag list ****************
  let tagsList = allTags?.data && allTags?.data;

  useEffect(() => {
    if (userID && accessToken) {
      dispatch(getAllTags(Number(userID), accessToken));
    }
  }, [userID, accessToken]);

  // ***************** tags option list ****************
  const tagsOptions = tagsList && tagsList?.map((item) => {
    return {
      key: item?.id,
      cat: item?.name
    }
  });

  // ***************** tags selected option ****************
  const selectedTagsOptions = selectTags?.map((item) => {
    return {
      key: item.key,
      cat: item.cat,
    };
  });
  // ***************** file upload in change ****************

  const handleChangeUpload = (event) => {
    const file = event?.target?.files?.[0];
    const fileName = file?.name;
    const fileType = file?.type;
    const fileSize = file?.size;
    setUploadedFileName(fileName);
    var payload = {
      name: fileName,
      file_size: fileSize,
      file: file,
      fileType: fileType,
    };

    if (fileFormat.indexOf(fileType) > -1) {
      // ***************** recording upload ****************
      function isNotWebmOrAvi(filename) {
        return !/(?:\.webm$|\.avi$)/i.test(filename);
      }
      // ***************** image upload ****************
      if (fileType?.indexOf("image") > -1) {
        setFormDetails({ ...formDetails, fileData: payload });
      }
      if (fileType?.indexOf("video") > -1) {
        // ***************** check video format ****************
        if (isNotWebmOrAvi(fileName)) {
          // ***************** get video durations ****************
          getVideoDuration(file, (res) => {
            if (!res) {
              payload.file_duration = "null";
              setFormDetails({ ...formDetails, fileData: payload });
            } else {
              payload.file_duration = res;
              setFormDetails({ ...formDetails, fileData: payload });
            }
          });
        } else {
          payload.file_duration = "null";
          setFormDetails({ ...formDetails, fileData: payload });
        }
      }
    } else {
      closeModal(false);
      failureMessagePopup(
        "Invalid format",
        "Only JPG, JPEG, PNG, GIF, or MP4, WEBM, AVI, MOV files allowed."
      );
    }
  };

  useEffect(() => {
    let { screenshots, videos } = progressDetails;
    sq_profile_progress(screenshots, videos);
  }, [progressDetails]);

  // ***************** handle submit for upload screenshots and videos ****************
  const handleSubmit = () => {
    // ***************** payload for submission ****************
    let payloadObj = {
      name: formDetails?.fileData?.name,
      user_id: Number(userID),
      access_token: accessToken,
      file_size: formDetails?.fileData?.file_size,
      file: formDetails?.fileData?.file,
      title: formDetails?.title,
      summary: formDetails?.summary,
      tags: selectTags?.map((el) => el.key),
    };
    if (!payloadObj?.name) {
      failureMessagePopup('Error Message', 'Please choose any file!')
      return;
    }
    if (formDetails?.fileData?.fileType?.indexOf("image") > -1) {
      // ***************** upload screenshots ****************
      dispatch(
        uploadScreenShot(payloadObj, (type, res) => {
          if (type === "success") {
            successMessagePopup(
              "Saved Successfuly",
              "Your file is uploaded Successfuly."
            );
            setTimeout(function () {
              // ***************** manage screenshots list update after uploading screenshots ****************
              dispatch(manageScreenShots(userID, accessToken));
              // ***************** user progress update after uploading screenshots ****************
              dispatch(manageUserProgress(userID, accessToken, function () { }));
              closeModal(false);
            }, 1200);
          } else if (type === "fail") {
            closeModal(false);
            failureMessagePopup("Error message", res?.message);
          }
        })
      );
    }
    if (formDetails?.fileData?.fileType?.indexOf("video") > -1) {
      // ***************** upload vides ****************
      const videoPayloadObj = {
        ...payloadObj,
        file_duration: formDetails?.fileData?.file_duration
      }
      dispatch(
        uploadRecordingVideo(videoPayloadObj, (type, res) => {
          if (type === "success") {
            successMessagePopup(
              "Saved Successfuly",
              "Your file is uploaded Successfuly."
            );
            setTimeout(function () {
              closeModal(false);
              // ***************** manage videos list update after uploading recording ****************
              dispatch(manageVideos(userID, accessToken));
              // ***************** user progress update after uploading recording ****************
              dispatch(manageUserProgress(userID, accessToken, function () { }));
            }, 1200);
          } else if (type === "fail") {
            failureMessagePopup("Error message", res?.message);
          }
        })
      );
    }
  };


  // ***************** handle add custom tags ****************
  const handleAddCustom = () => {
    const payload = {
      user_id: Number(userID),
      access_token: accessToken,
      name: tags
    }
    // ***************** add custom tags ****************
    dispatch(addTags(payload, (type, res) => {
      if (type === 'success') {
        const lastTagId = res?.data?.id
        const lastTagName = res?.data?.name
        setSelectTags([...selectTags, { key: lastTagId, cat: lastTagName }])
        // ***************** get tags list ****************
        dispatch(getAllTags(userID, accessToken));
        // ***************** remove default value of tags ****************
        setTags('');
      }
      else if (type === 'failed') {
        failureMessagePopup('Error Message', res?.message);
      }
    }))
  }

  return (
    <div id="quix-file-uploader">
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Upload File</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <div className="quix-dashboard-popup-body-inner">
              <div className="quix-upload-outer text-center">
                <div className="col-md-12 mb-3 text-start">
                  <div className="quix-item-uploader">
                    <input
                      id="input-file-upload"
                      className="quix-upload-input"
                      accept={fileFormat.join(",")}
                      type="file"
                      name="input-file-upload"
                      onChange={(e) => handleChangeUpload(e)}
                    />
                    <img src={uploaderIcon} />
                  </div>
                  <span style={{ fontSize: "14px" }}>{uploadedFileName}</span>
                </div>
                <div className="col-md-12 mb-1 text-start">
                  <label className="col-form-label">Title</label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    onChange={(e) =>
                      setFormDetails({ ...formDetails, title: e.target.value })
                    }
                    maxLength={255}
                  ></input>
                </div>
                <div className="col-md-12 mb-1 text-start">
                  <label className="col-form-label">summary</label>
                  <textarea
                    type="text"
                    name="summary"
                    className="form-control"
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        summary: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="col-md-12 mb-1 text-start edit-tag-outer">
                  <label className="col-form-label">Tags</label>
                  <div className="col-md-12 mb-1 text-start tags-item-outer">
                    <Multiselect
                      name="tags"
                      id="tags"
                      isObject={true}
                      onSearch={function noRefCheck() { }}
                      options={tagsOptions}
                      displayValue={"cat"}
                      onSelect={(val) => setSelectTags(val)}
                      placeholder="select tags"
                      selectedValues={selectedTagsOptions}
                      defaultValue={selectedTagsOptions}
                      onRemove={(val) => setSelectTags(val)}
                    />
                  </div>
                  <div className="col-md-12 mb-1 text-start">
                    <label className="col-form-label">Add Custom Tag</label>
                    <div className='quix-add-cutom-tags'>
                      <input
                        type="text"
                        name="tags"
                        className="form-control"
                        placeholder="add custom tag"
                        onChange={(e) => setTags(e?.target?.value)}
                        value={tags}
                        maxLength={255}
                      ></input>
                      <button onClick={() => handleAddCustom()} className='btn add-custom-tag-btn'>Add custom</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    onClick={() => closeModal(false)}
                    className="btn form-action-button form-cancel-button"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    className="btn form-action-button form-save-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allTags: state?.tag?.list,
  userDetails: state?.auth?.user_details
})

export default connect(mapStateToProps, { manageVideos })(FileUploader);
